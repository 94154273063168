///api/sys/crmCust/selectPageList
import request from '../utils/request';

export const getData = (param) => {
    return request({
        url: '/sys/crmCust/selectPageList',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//添加意向客户
export const addCust = (param) => {
    return request({
        url: '/sys/crmCust/addCust',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//修改意向
export const updateById = (param) => {
    return request({
        url: '/sys/crmCust/updateById',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//根据意向客户id查询底下多个联系人 
export const selectListByCrmCustId = (crmCustId) => {
    return request({
        url: '/sys/crmCustRelation/selectListByCrmCustId',
        method: 'GET',
        params: {crmCustId},
    });
};
//添加意向客户联系记录 
export const addCrmContact = (param) => {
    return request({
        url: '/sys/CrmContact/addCrmContact',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//给意向客户添加底下多个联系人 
export const addCrmCustRelation = (param) => {
    return request({
        url: '/sys/crmCustRelation/addCrmCustRelation',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//根据id删除意向客户其他联系人
export const deleteById = (id) => {
    return request({
        url: '/sys/crmCustRelation/deleteById',
        method: 'GET',
        params: {id},
    });
};
//招商导入
export const crmCust = (file) => {
    return request({
        url: '/sys/crmCust/importCrmCust',
        method: 'POST',
        data: file,
    });
};
//根据id修改意向客户多个联系人信息 
export const updateCrmCustRelationById = (param) => {
    return request({
        url: '/sys/crmCustRelation/updateCrmCustRelationById',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//意向客户签单
export const addCrmOrders = (param) => {
    return request({
        url: '/sys/crmOrders/addCrmOrders',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//根据订单id修改订单信息 
export const updateCrmOrdersById = (param) => {
    return request({
        url: '/sys/crmOrders/updateCrmOrdersById',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//分页条件查询联系记录列表 
export const pageListByQuery = (query) => {
    return request({
        url: '/sys/CrmContact/pageListByQuery',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//分页条件查询客户签单列表 
export const pageOrderList = (query) => {
    return request({
        url: '/sys/crmOrders/pageListByQuery',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//获取招商加盟环节列表 
export const selectList = (query) => {
    return request({
        url: '/sys/CrmServiceSet/selectList',
        method: 'POST',
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//订单绑定环节时查询开启状态环节列表 

export const selectBindingLink = (crmOrdersId) => {
    return request({
        url: '/sys/crmServiceProcess/selectBindingLink',
        method: 'GET',
        params: {
            crmOrdersId
        },
    });
};
//给订单绑定签单后的环节
export const addCrmServiceProcess = (crmOrdersId,crmServiceSetIdList,) => {
    return request({
        url: `/sys/crmServiceProcess/addCrmServiceProcess/${crmOrdersId}`,
        method: 'POST',
        data: JSON.stringify(crmServiceSetIdList) ,
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

//开始或者完成环节 
export const beginOrAccomplishLink = (processId,type) => {
    return request({
        url: '/sys/crmServiceProcess/beginOrAccomplishLink',
        method: 'GET',
        params: {
            processId,
            type
        },
    });
};
//导出
export function exportCrmCust(query) {
    return request({
        url: '/sys/crmCust/exportCrmCust',
        method: 'post',
        data: query,
        responseType: 'arraybuffer'
    })
}

/***
 * 导出
 * @param query
 * @returns {AxiosPromise}
 */
export function exportExcel(query) {
    return request({
        url: '/sys/crmOrders/exportCrmOrders',
        method: 'post',
        data: JSON.stringify(query),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    })
}

//检测支付截图中的商户单号
export const checkPaymentImages = (paymentUrl) => {
    return request({
        url: '/sys/crmOrders/checkPaymentImages',
        method: 'POST',
        data: JSON.stringify(paymentUrl),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};


export const returnMoney = (param) => {
    return request({
        url: '/sys/crmOrders/submitRefund',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//获取 多级审核人
export const auditorList = (campusId,coachId,ordersId,type) => {
    return request({
        url: '/sys/auditor/list',
        method: 'GET',
        params: {
            campusId,
            coachId,
            ordersId,
            type
        },
    });
};





