<template>
    <div style="padding:40px">
        <div>
            <el-button @click="linkDialog=true" style="margin-bottom:20px;min-width:200px" type="primary">添加</el-button>
        </div>
        <el-table :data="LinkList" border>
            <el-table-column label="Id" prop="id"/>
            <el-table-column label="环节" prop="linkName"/>
            <el-table-column label="状态" prop="state">
                <template #default="scope">
                    <el-switch v-model="scope.row.state" :active-value="0" @change="changeState(scope.row.id)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template #default>
                    <el-button type="text" style="color:red">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination">
            <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="current"
            :total="total"
            @current-change="handlePageChange"
            ></el-pagination> 
        </div>
        <el-dialog title="添加环节" v-model="linkDialog" width="700px">
            <el-form label-width="120">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="环节">
                            <el-input v-model="parms.linkName" style="width:260px"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="状态">
                            <el-select v-model="parms.state" style="width:260px">
                                <el-option :value="0" label="开启"></el-option>
                                <el-option :value="1" label="关闭"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button @click="linkDialog=false">取消</el-button>
                <el-button @click="add" type="primary">添加</el-button>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import {selectList} from '@/api/attract.js'
    export default {
        data(){ 
            return{ 
                LinkList:[],
                query:{
                    pageIndex:1,
                    pageSize:10,
                },
                parms:{
                    linkName:'',
                    state:null,
                },
                total:null,
                current:1,
                linkDialog:false
            }
        },
        methods:{
            getData(){ 
                this.query.pageIndex = 1
                selectList(this.query).then(res=>{ 
                    console.log(res);
                    if(res.code == 200){ 
                        this.LinkList = res.data.records
                        this.total = res.data.total
                        this.current  = res.data.pages
                    }else{ 
                        this.$message.error('请求数据失败！')
                    }
                })
            },
            changeState(id){
                console.log(id);
            },
            add(){
                
            },
        },
        created(){ 
            this.getData()
        }
    }
</script>
